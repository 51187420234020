import React from 'react';
import styled from '@emotion/styled';

import { COLORS } from '../../constants';
import { FONT_GOTHAM_LIGHT } from '../../styles/fonts';

interface ProgressBarProps {
    max: number;
    value: number;
    label: string;
    className?: string;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

const ProgressBarContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    border-radius: 30px;
    box-shadow: inset 0px 0px 5.64px 0.36px rgba(28, 39, 41, 0.3);
    width: 100%;
    font-family: ${FONT_GOTHAM_LIGHT};
    font-size: 18px;
    overflow: hidden;
    color: #beb5b5;
`;

const Label = styled.div`
    padding: 4px 0 0;
    font-family: ${FONT_GOTHAM_LIGHT};
    font-size: 18px;
`;

const ProgressLine = styled.div<{ width: number }>`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: ${COLORS.lightGray2};
    transition: all 0.5s ease-in-out;
    width: ${({ width }) => width}%;
    z-index: -1;
`;

const ProgressBar: React.FC<ProgressBarProps> = ({
    max,
    value,
    label,
    className,
}) => {
    return (
        <Container className={className}>
            <ProgressBarContainer>
                {value}/{max}
                <ProgressLine width={(value / max) * 100} />
            </ProgressBarContainer>
            <Label>{label}</Label>
        </Container>
    );
};

export default ProgressBar;
