import React from 'react';
import styled from '@emotion/styled';

import { COLORS } from '../../constants';
import { FONT_GOTHAM_LIGHT } from '../../styles/fonts';
import { device } from '../commonStyled';

const Container = styled.div<{ isError: boolean }>`
    padding: 0 0 17px;
    margin: 0 0 25px;
    border-bottom: 3px solid
        ${({ isError }) => (isError ? COLORS.red : COLORS.lightGray)};
`;

const Text = styled.div`
    margin: 0 0 25px;
    font-size: 20px;
    line-height: 26px;
    font-family: ${FONT_GOTHAM_LIGHT};

    @media ${device.mobileMedium} {
        font-size: 16px;
    }
`;

const RadioContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 4px 0 0;
`;

const RadioWrap = styled.div`
    position: relative;
    margin: 0 25px 20px 0;
`;

const RadioInput = styled.input`
    position: absolute;
    left: 0;
    width: 1px;
    height: 1px;
    opacity: 0;
`;

const RadioLabel = styled.label<{ checked: boolean }>`
    display: block;
    padding: 3px 42px 0 0;
    font-size: 20px;
    line-height: 25px;
    font-family: ${FONT_GOTHAM_LIGHT};
    cursor: pointer;

    &:before {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        content: '';
        width: 32px;
        height: 32px;
        box-sizing: border-box;
        background: #ffffff;
        box-shadow: inset 0 0 6px 0 rgba(28, 39, 41, 0.59);
        border-radius: 50%;
    }

    &:after {
        position: absolute;
        top: 9px;
        right: 9px;
        display: block;
        content: '';
        width: 14px;
        height: 14px;
        box-sizing: border-box;
        border-radius: 50%;
        background: ${COLORS.red};
        opacity: ${({ checked }) => (checked ? 1 : 0)};
        transition: all 0.2s ease;
        transform: scale(${({ checked }) => (checked ? 1 : 0)});
    }

    @media ${device.mobileMedium} {
        font-size: 16px;
    }
`;

interface TestItemProps {
    count: number;
    question: string;
    selected: number;
    onSelect(id): void;
    isError: boolean;
    values: {
        value: number;
        label: string;
    }[];
}

const TestItem: React.FC<TestItemProps> = ({
    count,
    values,
    question,
    selected,
    onSelect,
    isError,
}) => {
    const isSelected = values.some(({ value }) => value === selected);

    return (
        <Container data-selected={isSelected} isError={isError && !isSelected}>
            <Text>Вопрос {count}:</Text>
            <Text>{question}</Text>
            <RadioContainer>
                {values.map(({ label, value }) => (
                    <RadioWrap key={label}>
                        <RadioInput
                            type="radio"
                            id={`${count}-${value}`}
                            name={`${count}-${value}`}
                            value={value}
                            checked={value === selected}
                            onChange={() => {
                                onSelect(value);
                            }}
                        />
                        <RadioLabel
                            checked={value === selected}
                            htmlFor={`${count}-${value}`}
                        >
                            {label}
                        </RadioLabel>
                    </RadioWrap>
                ))}
            </RadioContainer>
        </Container>
    );
};

export default TestItem;
